import { HashRouter as Router, Routes, Route } from 'react-router-dom';
//import Vplayer from './vplayer';
import Renderer from './renderer'

function App() {
  return (
    <div>
      {/* Media Player Component */}
      <Renderer />
    </div>
  );
}

export default App;