import React, { useEffect, useState  } from "react";
//import {useNavigate} from "react-router-dom";
import Vplayer from './vplayer';
//import checkSession from './pwep';

/*
function Renderer() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Use null to track the initial loading state

  useEffect(() => {
    // Check the session when the component mounts
    async function verifySession() {
      const isLoggedIn = await checkSession(); // Assumes a boolean return from checkSession
      console.log(isLoggedIn); // For debugging
      setIsAuthenticated(isLoggedIn);
    }
    verifySession();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Show this while session is being verified
  }

  if (!isAuthenticated) {
    window.location.href = "/"; // Redirect to login page if not authenticated
    return null; // Prevent further rendering while redirecting
  }

  return <Vplayer />; // Render Vplayer if authenticated
}

export default Renderer;
*/

function Renderer() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Use null for loading state

  useEffect(() => {
    async function verifySession() {
      try {
        const response = await fetch("https://trillionchances.com/react_php_ver", {
          credentials: "include", // Ensures cookies are sent with the request
        });
        const data = await response.json();
        setIsAuthenticated(data.authenticated);
      } catch (error) {
        console.error("Session verification failed:", error);
        setIsAuthenticated(false); // Fail-safe: assume unauthenticated on error
      }
    }

    verifySession();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Show loading while checking the session
  }

  if (!isAuthenticated) {
    window.location.href = "https://trillionchances.com"; // Redirect to login page if not authenticated
    return null; // Prevent further rendering while redirecting
  }

  return <Vplayer />; // Render Vplayer if authenticated
}

export default Renderer;